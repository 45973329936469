/* GOOGLE FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

/* GENERAL */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --header-height: 3rem;

    /* COLORS */
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.7);
  
    /* ANIMATION */
    --transition: all 400ms ease;
  
    /* DISPLAY */
    --container-width-lg: 85%;
    --container-width-md: 87%;
    --container-width-sm: 90%;

    /* SPACING */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 1.5rem;
    --spacing-xlarge: 2rem;
    --spacing-xxlarge: 4rem;
    --spacing-xxxlarge: 8rem;

    /* FONT SIZE */
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --tiny-font-size: 0.625rem;
}

/* RESPONSIVE FONT SIZE */
@media screen and (max-width: 992px) {
    :root {
      --big-font-size: 2.75rem;
      --h1-font-size: 1.5rem;
      --h2-font-size: 1.25rem;
      --h3-font-size: 1rem;
      --normal-font-size: 0.938rem;
      --small-font-size: 0.813rem;
      --smaller-font-size: 0.75rem;
    }
  }



html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Poppins", sans-serif;
    font-size: var(--normal-font-size);
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png);
}

/* HEADINGS */
h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: var(--h1-font-size);
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin: var(--spacing-xlarge);
}

.text-light {
    color: var(--color-light);
}

/* LINKS */
a {
    color: var(--color-primary);
    transition: var(--transition);
}
  
a:hover {
    color: var(--color-white);
}

/* LAYOUT */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

.grid {
    display: grid;
    gap: var(--spacing-large);
}

section {
    padding-top: 5rem;
}

/* BUTTONS */
.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    background-color: transparent;
    padding: var(--spacing-medium);
    border-radius: 2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
  
.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}
  
/* IMAGE */
img {
    display: block;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}


/* MEDIA QUERIES (MEDIUM) */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
}
  
  /* MEDIA QUERIES (SMALL) */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}